<script>
	import Barmenia from '$lib/components/ads/barmenia.svelte';
	import Roland from '$lib/components/ads/roland.svelte';
	import Badge from '$lib/components/badge.svelte';
	import Button from '$lib/components/button.svelte';
	import Chat from '$lib/components/chat.svelte';
	import Footer from '$lib/components/footer.svelte';
	import Header from '$lib/components/header.svelte';
	import RolandPopup from '$lib/components/ads/roland-popup.svelte';
	import { Splide, SplideSlide, SplideTrack } from '@splidejs/svelte-splide';
	import '@splidejs/svelte-splide/css/core';
	import { CarIcon, CrossIcon, UsersRoundIcon } from 'lucide-svelte';
	import SvelteSeo from 'svelte-seo';

	let activeSlide = 0;
</script>

<SvelteSeo
	applicationName="Frag den Lenßen"
	title="Sofortige, kostenlose Beantwortung Deiner Rechtsfragen mit Ingo Lenßen"
	description="Frag meinen digitalen Zwilling! Ich helfe Dir absolut kostenlos und sofort zu Deinem Recht. Das ist mein Antrieb, mein Bestreben und meine Motivation. Dein Ingo Lenßen"
	openGraph={{
		title: 'Sofortige, kostenlose Beantwortung Deiner Rechtsfragen mit Ingo Lenßen',
		description:
			'Frag meinen digitalen Zwilling! Ich helfe Dir absolut kostenlos und sofort zu Deinem Recht. Das ist mein Antrieb, mein Bestreben und meine Motivation. Dein Ingo Lenßen',
		type: 'website',
		locale: 'de_DE',
		url: 'https://frag-den-lenssen.de',
		site_name: 'Frag den Lenßen'
	}}
	canonical="https://frag-den-lenssen.de"
/>
<RolandPopup />

<main>
	<Header isTransparent />
	<section class="relative bg-gray-50">
		<enhanced:img
			src="../../assets/ingo3.png?w=982;640"
			class="absolute top-0 h-full max-h-[100svh] min-w-96 object-cover object-center opacity-15 sm:-left-[5%] sm:w-1/2"
			sizes="(min-width: 1024px) 982px, 640px"
		/>
		<div class="g container min-h-[100svh] pt-40 sm:pt-52 xl:grid xl:grid-cols-2 xl:pt-0">
			<div class="z-10 flex w-full flex-col justify-center xl:h-full">
				<div class="mb-4 text-balance text-xs font-medium sm:text-lg">
					Ich helfe Dir absolut kostenlos und sofort zu Deinem Recht
				</div>
				<h1
					class="mb-6 text-balance text-3xl font-bold text-gray-800 sm:text-4xl md:text-5xl xl:text-6xl 2xl:text-7xl"
				>
					<a rel="external" href="/chat" class="cursor-pointer">
						Frag meinen <span class="text-primary">digitalen Zwilling!</span>
					</a>
				</h1>
				<p class="max-w-lg text-balance text-lg">
					Lust zu chatten? Stelle Deine Frage und bekomme eine Antwort - sofort & kostenlos.
				</p>
				<div class="mt-8 hidden xl:block">
					<Button rel="external" href="/chat">Jetzt fragen</Button>
				</div>
			</div>
			<div
				class="relative z-20 mt-12 flex flex-col items-center justify-center sm:mt-20 xl:mt-0 xl:h-full"
			>
				<Chat />
			</div>
		</div>
	</section>
	<section>
		<div class="container py-20">
			<Badge class="mb-3">Unsere Rechtsgebiete</Badge>
			<h2 class="mb-3.5 text-2xl font-bold text-gray-800 sm:text-3xl md:text-4xl">
				Darin sind wir im Moment besonders gut ...
			</h2>
			<div class="mt-12 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
				<a
					href="/rechtsgebiete/verkehrsrecht"
					class="flex flex-col border border-gray-200 px-6 py-6"
				>
					<CarIcon class="mb-4 size-12 text-primary" />
					<div>
						<h3 class="mb-2 text-lg font-medium">Verkehrsrecht</h3>
						<p class="mb-2">Beispielfragen:</p>
						<ul class="ml-3.5 list-outside list-disc space-y-1">
							<li>Bei wie vielen Punkten verliere ich den Führerschein?</li>
							<li>Muss ich bei 1,1 Promille die MPU machen?</li>
						</ul>
					</div>
				</a>
				<a href="/rechtsgebiete/sozialrecht" class="flex flex-col border border-gray-200 px-6 py-6">
					<UsersRoundIcon class="mb-4 size-12 text-primary" />
					<div>
						<h3 class="mb-2 text-lg font-medium">Sozialrecht</h3>
						<p class="mb-2">Beispielfragen:</p>

						<ul class="ml-3.5 list-outside list-disc space-y-1">
							<li>Wie viel Geld darf ich haben, wenn ich Bürgergeld beziehe?</li>
							<li>Passiert mir was, wenn ich mich trotz Aufforderung nicht beim Amt melde?</li>
						</ul>
					</div>
				</a>
				<a href="/rechtsgebiete/erbrecht" class="flex flex-col border border-gray-200 px-6 py-6">
					<CrossIcon class="mb-4 size-12 text-primary" />
					<div>
						<h3 class="mb-2 text-lg font-medium">Erbrecht</h3>
						<p class="mb-2">Beispielfragen:</p>

						<ul class="ml-3.5 list-outside list-disc space-y-1">
							<li>
								Ich habe Angst, dass ich nichts von meinen Eltern erbe, kann ich schon was bekommen?
							</li>
							<li>
								Wir sind nicht geschieden, mein Mann ist verstorben und hat alles seiner neuen
								Partnerin überschrieben, gehe ich leer aus?
							</li>
						</ul>
					</div>
				</a>
			</div>
			<p class="mt-12 text-lg">... und ständig kommen neue Rechtsgebiete dazu.</p>
			<div class="mt-12">
				<Button rel="external" href="/chat">Zum digitalen Ingo</Button>
			</div>
		</div>
	</section>
	<section class="py-12">
		<Roland />
	</section>
	<section class="bg-gray-50">
		<div class="container py-20">
			<Badge class="mb-3">So funktioniert es</Badge>
			<h2 class="text-balance text-2xl font-bold text-gray-800 sm:text-3xl md:text-4xl">
				In einem einfachen Schritt bekommst Du eine kostenlose Antwort zu Deiner Frage.
			</h2>
			<div class="mt-6">
				<Splide
					options={{
						perPage: 1,
						pagination: false,
						gap: '1rem',
						autoplay: false,
						rewind: false
					}}
					hasTrack={false}
					on:moved={(e) => {
						if (!e) return;
						activeSlide = e.detail.index;
					}}
				>
					<div class="splide__arrows mb-8 flex items-center justify-between gap-2">
						<div>
							<span class="font-medium">Schritt {activeSlide + 1} von 2</span>
						</div>
						<div class="flex gap-2">
							<button
								class="splide__arrow splide__arrow--prev flex size-12 items-center justify-center rounded-full border border-gray-200 bg-white disabled:opacity-50"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="lucide lucide-chevron-left"><path d="m15 18-6-6 6-6" /></svg
								>
								<span class="sr-only">Vorheriger Schritt</span>
							</button>
							<button
								class="splide__arrow splide__arrow--next flex size-12 items-center justify-center rounded-full border border-gray-200 bg-white disabled:opacity-50"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="lucide lucide-chevron-right"><path d="m9 18 6-6-6-6" /></svg
								>
								<span class="sr-only">Nächster Schritt</span>
							</button>
						</div>
					</div>
					<SplideTrack>
						<SplideSlide>
							<div class="flex flex-col bg-white px-4 py-10 sm:flex-row sm:items-center md:p-4">
								<div class="order-2 max-w-xl sm:order-1 sm:px-4">
									<h3
										class="mb-3 text-balance text-lg font-semibold text-gray-800 sm:mb-6 sm:text-xl md:text-2xl lg:text-3xl"
									>
										So stellst Du Deine Frage
									</h3>
									<p>
										Schreib Deine Frage einfach hier in den Chat. Mein digitaler Zwilling überprüft
										die aktuelle Gesetzeslage und wird auf Deine Frage ausführlich eingehen.
									</p>
									<div class="mt-12">
										<Button rel="external" href="/chat">Jetzt Ingo fragen</Button>
									</div>
								</div>
								<div class="order-1 mb-6 sm:order-2 sm:mb-0">
									<enhanced:img
										src="../../assets/carousel-2.png?w=600;400;250"
										class="max-h-[600px] w-full object-cover"
										sizes="(max-width: 640px) 250px, (min-width: 1024px) 600px, 400px"
										loading="lazy"
									/>
								</div>
							</div>
						</SplideSlide>
						<SplideSlide>
							<div class="flex flex-col bg-white px-4 py-10 sm:flex-row sm:items-center md:p-4">
								<div class="mb-6 sm:mb-0">
									<enhanced:img
										src="../../assets/carousel-3.png?w=600;400;250"
										class="max-h-[600px] w-full object-cover"
										sizes="(max-width: 640px) 250px, (min-width: 1024px) 600px, 400px"
										loading="lazy"
									/>
								</div>
								<div class="max-w-xl sm:pl-4">
									<h3
										class="mb-3 text-balance text-lg font-semibold text-gray-800 sm:mb-6 sm:text-xl md:text-2xl lg:text-3xl"
									>
										So sieht Deine Antwort aus
									</h3>
									<p>
										Mein digitaler Zwilling gibt Dir Deine Antwort, natürlich leicht verständlich -
										und vor allem kein Juristendeutsch.
									</p>
									<div class="mt-12">
										<Button rel="external" href="/chat">Jetzt Ingo fragen</Button>
									</div>
								</div>
							</div>
						</SplideSlide>
					</SplideTrack>
				</Splide>
			</div>
			<div class="mt-12">
				<Button href="/so-fragst-du">Mehr erfahren</Button>
			</div>
		</div>
	</section>
	<section class="py-12">
		<Barmenia />
	</section>
	<section class="relative py-32">
		<div class="container">
			<Badge class="mb-3">Über mich</Badge>
			<h2 class="mb-8 text-balance text-2xl font-bold text-gray-800 sm:text-3xl md:text-4xl">
				<span class="block">Wer bin ich - Ingo Lenßen </span>
			</h2>
			<div class="prose max-w-4xl text-foreground">
				<p>
					Ich, Ingo Lenßen, wurde am 2. Januar 1961 in Krefeld geboren und bin ein prominenter
					deutscher Jurist sowie Fachanwalt für Strafrecht. Nach meinem erfolgreichen Abschluss des
					Rechtswissenschaftsstudiums an der Universität Konstanz eröffnete ich 1990 meine eigene
					Anwaltskanzlei am Bodensee, in der ich bis heute praktiziere.
				</p>
				<p>
					Meinen Weg ins Fernsehen fand ich Anfang der 2000er Jahre durch die Sat.1-Rechtsshow
					„Richter Alexander Hold“. Von 2003 bis 2009 übernahm ich die Hauptrolle in „Lenßen &
					Partner“, einer täglichen Serie, die mich deutschlandweit bekannt machte. Von April 2019
					bis Dezember 2020 folgte die nächste Daily mit „Lenßen übernimmt“, und auch heute trete
					ich regelmäßig in anderen Fernsehformaten auf.
				</p>
				<p>
					Zusätzlich habe ich mich als Autor juristischer Ratgeber etabliert, in denen ich komplexe
					rechtliche Inhalte für ein breites Publikum verständlich aufbereite. Mit meinem Angebot
					„Frag den Lenßen“ möchte ich Menschen erreichen und ihnen die Möglichkeit bieten, ihre
					dringlichen Fragen im Leben kostenlos und diskret beantwortet zu bekommen.
				</p>
			</div>
		</div>
		<enhanced:img
			class="absolute inset-0 -z-10 h-full max-h-full w-full object-cover object-[50%_20%] opacity-20"
			src="../../assets/ingo-bg.webp"
			alt=""
			sizes="min(1280px, 100vw)"
			loading="lazy"
		/>
	</section>
	<section class="overflow-hidden bg-primary text-white">
		<div class="container relative py-20">
			<div class="relative z-10 max-w-xl">
				<h2 class="mb-16 text-balance text-2xl font-medium text-white sm:text-3xl md:text-4xl">
					Darum kannst Du Dich auf meinen <span class="font-bold">digitalen Zwilling</span> verlassen.
				</h2>
				<div class="mb-12">
					<h3
						class="mb-3 text-balance text-lg font-medium text-white sm:text-xl md:text-2xl lg:text-3xl"
					>
						Aktuell und geprüft
					</h3>
					<p>
						Ich und mein erfahrenes Team aus Rechtsanwälten überprüfen regelmäßig meinen digitalen
						Zwilling. So stellen wir sicher, dass auch aktuelle Fragestellungen von meinem digitalen
						Zwilling beantwortet werden.
					</p>
				</div>
				<div class="mb-12">
					<h3
						class="mb-3 text-balance text-lg font-medium text-white sm:text-xl md:text-2xl lg:text-3xl"
					>
						Sicherheit Deiner Daten
					</h3>
					<p>
						Deine Daten sind bei uns sicher, wir und unsere Partner behandeln Deine Daten
						selbstverständlich vertraulich. So ist Dein Anliegen bei meinem digitalen Zwilling
						sicher.
					</p>
				</div>
				<div class="mb-12">
					<h3
						class="mb-3 text-balance text-lg font-medium text-white sm:text-xl md:text-2xl lg:text-3xl"
					>
						Absolut kostenlos
					</h3>
					<p>
						Diese Seite ist von mir für Dich. Das ist mein Antrieb, mein Bestreben und meine
						Motivation.
					</p>
				</div>
				<div>
					<p class="mb-6 text-lg font-medium">Ich freue mich auf Deine Frage!</p>
					<p class="mb-3">Dein</p>
					<enhanced:img
						src="../../assets/unterschrift.png?w=250"
						alt="Ingo Lenßen"
						class="mb-3 h-16 w-auto"
						loading="lazy"
					/>
					<p>Ingo Lenßen</p>
				</div>
				<div class="mt-10 pt-6">
					<Button rel="external" href="/chat" variant="white">Jetzt Deine Frage stellen</Button>
				</div>
			</div>
			<enhanced:img
				src="../../assets/ingo2.png"
				class="absolute -right-1/3 bottom-0 top-0 h-full w-full object-cover object-center opacity-20 lg:right-0 lg:w-[55%] lg:opacity-100"
				sizes="min(800px, 100vw)"
				loading="lazy"
			/>
		</div>
	</section>
</main>
<Footer />
