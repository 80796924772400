<script>
	import { onMount } from 'svelte';
	import * as Dialog from '../ui/dialog';
	import Button from '../button.svelte';

	let open = $state(false);

	onMount(() => {
		setTimeout(() => {
			if (sessionStorage.getItem('roland-popup')) return;
			sessionStorage.setItem('roland-popup', 'true');
			open = true;
		}, 250);
	});
</script>

<Dialog.Root bind:open>
	<Dialog.Content class="max-w-4xl">
		<div class="flex flex-col items-center justify-center py-6 lg:flex-row lg:justify-start gap-8">
			<div class="order-2 lg:order-1">
				<Dialog.Header class="sm:text-center lg:!text-left">
					<Dialog.Title class="text-3xl">Profitiere von unserem exklusiven Angebot</Dialog.Title>
					<Dialog.Description class="!mt-4 text-balance text-lg text-foreground">
						Schließe Deine <b>Privatrechtsschutzversicherung</b> ROLAND jetzt online ab: Bis zu
						<b>30% Rabatt </b>
						und zusätzlich ein <b>30€-Amazon-Gutschein</b> - für Dich und Deine Rechte!
					</Dialog.Description>
				</Dialog.Header>
				<div
					class="mt-6 flex flex-col-reverse items-center gap-8 lg:mt-8 lg:flex-row lg:justify-between"
				>
					<div>
						<Button variant="roland" target="_blank" href="/api/r/roland">
							Jetzt online abschließen
						</Button>
					</div>
					<enhanced:img
						loading="lazy"
						src="../../../assets/ads/roland-rechtsschutz-logo.png"
						class="h-20 w-auto"
					/>
				</div>
			</div>
			<div class="order-1 mb-4 shrink-0 lg:order-2 lg:mb-0">
				<enhanced:img
					loading="lazy"
					src="../../../assets/ads/roland.png"
					class="h-full max-h-32 w-auto shrink-0 lg:max-h-64"
					alt="30%-Rabatt-Grafik"
				/>
			</div>
		</div>
	</Dialog.Content>
</Dialog.Root>
