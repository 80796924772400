<script lang="ts">
	import Badge from '../badge.svelte';
	import Button from '../button.svelte';
</script>

<div class="container py-12">
	<div class="flex grid-cols-12 flex-col gap-12 lg:grid">
		<div class="col-span-7 flex flex-col justify-center lg:pr-12">
			<Badge class="mb-6">Für Dich und Deine Rechte</Badge>
			<div class="mb-3.5 text-2xl font-medium text-gray-800 sm:text-3xl md:text-4xl">
				<span>Die Rechtsschutzversicherung von unserem Partner Roland:</span>
				<span class="font-bold">Im Streitfall an Deiner Seite</span>
			</div>
			<div class="prose mt-6 text-lg prose-a:font-bold prose-a:no-underline">
				<p>
					Für den besten Schutz für Dich und Deine Rechte vertraut Ingo Lenßen den Experten von
					ROLAND Rechtsschutz - einem der Marktführer in diesem Bereich. Dank dieser Partnerschaft
					bist Du stressfrei im Alltag und Berufsleben unterwegs.
				</p>
			</div>
			<p class="mt-6 text-xl font-semibold">
				<b
					>Bis zu 30% Rabatt und zusätzlich einen Amazon-Gutschein im Wert von 30€ - exklusiv mit
					Ingo für Dich!
				</b>
			</p>
			<div class="mt-9 flex flex-col-reverse gap-8 md:flex-row md:items-center md:justify-between">
				<div>
					<Button variant="roland" target="_blank" href="/api/r/roland"
						>Jetzt online abschließen</Button
					>
				</div>
				<enhanced:img
					loading="lazy"
					src="../../../assets/ads/roland-rechtsschutz-logo.png"
					class="h-20 w-auto"
				/>
			</div>
		</div>
		<div class="col-span-5">
			<enhanced:img loading="lazy" src="../../../assets/ads/roland.png" class="object-cover" />
		</div>
	</div>
</div>
