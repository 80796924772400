<script lang="ts">
	import { fade } from 'svelte/transition';
	import Ingo from '../../assets/ingo3.png';
	import { Textarea } from './ui/textarea';
	import { Button } from './ui/button';
	import { ArrowBigUp, UserIcon } from 'lucide-svelte';
	import type { FormEventHandler } from 'svelte/elements';

	const Messages = [
		'Ich habe eine Frage zum Thema Verkehr, die ich Dir gerne stellen würde.',
		'Klar, ich bin hier, um Dir zu helfen. Stell mir Deine Frage - bitte verwende keine persönlichen oder sensiblen Informationen in Deiner Nachricht. Ich suche nach einer Antwort für Dein Anliegen!'
	];

	let input = $state('');
	let messages = $state<string[]>(['Hallo, ich bin Ingo - schön, Dich kennenzulernen. Meine Spezial-Bereiche sind Erbrecht, Sozialrecht und Verkehrsrecht. Wie kann ich Dir weiterhelfen?']);
	let finished = $state(false);

	$effect(() => {
		// run an interval to simulate a chat
		const interval = setInterval(() => {
			if (Messages.length > 0) {
				messages = [...messages, Messages.shift()!];
			} else {
				finished = true;
				clearInterval(interval);
			}
		}, 1000);
	});

	const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
		event.preventDefault();
		if (input.trim().length === 0) return;
		window.open(`/chat?message=${encodeURIComponent(input)}`, '_blank');
	};
</script>

<div class="min-h-[450px] w-full rounded-sm border border-gray-200 bg-white p-4 shadow sm:p-8">
	<div class="mb-4 flex flex-col space-y-6">
		{#each messages as message, index (index)}
			<div
				class="flex items-start gap-4 xl:items-center {index % 2 === 0
					? 'self-start'
					: 'self-end'}"
			>
				{#if index % 2 === 0}
					<img
						src={Ingo}
						alt="Ingo Lenßen"
						class="h-12 w-12 shrink-0 rounded-full bg-primary/20 object-contain"
					/>
				{/if}
				<div
					class="flex max-w-lg items-start gap-4 p-3 text-gray-900 shadow xl:items-center {index %
						2 ===
					0
						? 'bg-gray-50'
						: 'bg-indigo-50'}"
					in:fade
				>
					<p>{message}</p>
				</div>
			</div>
		{/each}
	</div>
	{#if finished}
		<form onsubmit={onSubmit} class="pt-6" in:fade>
			<div class="relative">
				<Textarea
					bind:value={input}
					placeholder="Gib Deine Rechtsfrage ein und lass sie von Ingo beantworten!"
					class="max-h-[200px] min-h-[80px] resize-none pr-16 sm:pr-48 !text-base placeholder:!text-base"
					rows={1}
					autofocus
				/>
				<div class="absolute inset-y-0 right-2 flex items-center space-x-2">
					<Button type="submit" disabled={input.trim().length === 0}>
						<span class="hidden sm:inline">
							Nachricht senden
						</span>
						<ArrowBigUp class="!w-6 !h-6 inline sm:hidden" />
					</Button>
				</div>
			</div>
		</form>
	{/if}
</div>
